exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consortium-js": () => import("./../../../src/pages/consortium.js" /* webpackChunkName: "component---src-pages-consortium-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demos-ibn-js": () => import("./../../../src/pages/demos/ibn.js" /* webpackChunkName: "component---src-pages-demos-ibn-js" */),
  "component---src-pages-demos-inc-js": () => import("./../../../src/pages/demos/inc.js" /* webpackChunkName: "component---src-pages-demos-inc-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-objective-js": () => import("./../../../src/pages/objective.js" /* webpackChunkName: "component---src-pages-objective-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-work-description-js": () => import("./../../../src/pages/workDescription.js" /* webpackChunkName: "component---src-pages-work-description-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

